/* eslint-disable no-console */
import logo from '../svg/waldruh-dietramszell-logo.svg';

const $ = window.jQuery;
const panoAjaxObject = window.panoAjaxObject;

export function addPrintOnlyLogo() {
	if ( panoAjaxObject && panoAjaxObject.sok && panoAjaxObject.sok === 'drz' ) {
		$( 'section.output' ).prepend( '<div class="print-logo-wrap"></div>' );
		$( 'div.print-logo-wrap' ).html( logo );
	}
}

import form from './form';
import { addPrintOnlyLogo } from './logo';

const panoAjaxObject = window.panoAjaxObject;

export function app() {
	form();
	if ( panoAjaxObject && panoAjaxObject.sok && panoAjaxObject.sok === 'drz' ) {
		addPrintOnlyLogo();
	}
}
